import React from 'react'

import Report from './Report';

const Riepilogo = () => {
  return (
    <div className='app__report-ore'>
      <Report/>
    </div>
  )
}

export default Riepilogo